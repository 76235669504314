/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <main
        id="scene-holder"
        className="bg-black p-6 md:h-full lg:h-screen lg:p-0"
      >
        {children}
      </main>
      <footer className="bg-black text-gdm-gold text-center">
        © {new Date().getFullYear()}, Built by
        {` `}
        <a
          title="Link to Dev Profile - Open in new window"
          target="_blank"
          rel="noreferrer"
          href="https://www.malt.fr/profile/thomasbocquentin"
        >
          Thomas Bocquentin
        </a>
      </footer>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
